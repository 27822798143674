import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRequestSignature } from "common/hooks/useRequestSignature";
import { useFetchCommissionsFiles } from "modules/admin/hooks/Commissions/useFetchCommissionsFiles";
import { useCommissionsService } from "modules/admin/services/Commissions";
import React, { useEffect, useState } from "react";

export const CommissionsFilesList = () => {
  const [signature, setSignature] = useState(null);
  const requestSignature = useRequestSignature();
  const { data: commissionsFilesData, setParams, params } = useFetchCommissionsFiles();
  const { fetchCommissionsFile } = useCommissionsService();

  useEffect(() => {
    const getSignature = async () => {
      const sig = await requestSignature();
      setSignature(sig);
      setParams({
        ...params,
        signature: sig,
      });
    };
    if (!signature) {
      getSignature();
    }
  }, [params, requestSignature, setParams, signature]);

  const handleGetCommissionsFile = async (date) => {
    await fetchCommissionsFile({
      date,
      signature,
    });
  };

  return (
    <div>
      <hr className="my-4" />
      <h2 className="text-xl m-2 font-semibold">Commissions files for the last 30 days</h2>
      {commissionsFilesData ? (
        <div className="flex flex-col gap-1">
          {new Array(...commissionsFilesData).reverse().map((item) => (
            <div className="border border-gray-300 p-2 rounded flex justify-between" key={item}>
              <div>
                <FontAwesomeIcon icon={faFileExcel} className="mr-2 text-green-500" />
                {new Date(item).toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </div>
              <button
                onClick={() => {
                  handleGetCommissionsFile(item);
                }}
                className="bg-[#87BAD7] text-[#051F40] px-2 py-1"
              >
                Download
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div>no data</div>
      )}
    </div>
  );
};
