import { Button } from "common/components";
import { Input } from "common/components/Input";
import searchIcon from "assets/icons/pennyAuction/search-icon.svg";
import filterSquareIcon from "assets/icons/filter-square.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { onGetNavigateUrl } from "../utils/onGetNavigateUrl";

export const SearchBar = ({
  categories = [],
  loading,
  error,
  handleSearchOptions,
  category,
  search,
  status,
  sortTypes = [],
  sortType,
  page,
}) => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(category ?? "");
  const [searchbar, setSearchbar] = useState(search ?? "");
  const [selectedSort, setSelectedSort] = useState(sortType ?? "");
  const options = ["categories", ...categories.map((category) => category.name)];
  const onSearch = ({ selCategory, selSort }) => {
    // console.debug({ selectedCategory, searchbar, status, selectedSort });
    const url = onGetNavigateUrl({
      page: 1,
      category: selectedCategory ? selectedCategory.replace(/\s/g, "_") : null,
      search: searchbar.replace(/\s/g, "_") || null,
      sort: selectedSort || null,
    });
    navigate(url);
    if (handleSearchOptions)
      handleSearchOptions({
        newCategory: selCategory || selectedCategory,
        newSearch: searchbar,
        newStatus: status,
        newSortType: selSort || selectedSort,
      });
  };

  /*   useEffect(() => {
    if (selectedCategory) {
      onSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedSort) {
      onSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort]); */

  return (
    <div className="w-full flex flex-col md:flex-row">
      <>
        {loading ? (
          "Loading..."
        ) : (
          <select
            placeholder="Categories"
            className="select-search-bar w-full px-2 py-3 md:outline-none md:border-black md:border-r-2 md:w-1/6"
            value={selectedCategory}
            onChange={(e) => {
              console.debug({ value: e.target.value });
              onSearch({ selCategory: e.target.value === "categories" ? "" : e.target.value });
              setSelectedCategory(e.target.value === "categories" ? "" : e.target.value);
            }}
          >
            {options.map((o) => {
              return (
                <option key={o} value={o}>
                  {o}
                </option>
              );
            })}
          </select>
        )}
        <Input
          className="h-12 rounded-none md:outline-none"
          placeholder={"Search products"}
          value={searchbar}
          onChange={(e) => {
            setSearchbar(e.target.value);
          }}
        />
        <Button
          label={"Search"}
          className="w-full rounded-none border-none bg-electric-blue flex text-white justify-center md:w-1/6"
          icon={searchIcon}
          onClick={() => {
            onSearch();
          }}
        />
        <img
          src={filterSquareIcon}
          alt="filter square"
          className="w-4 h-4 relative left-7 mt-4 hidden md:block"
        />
        <select
          placeholder="Filter by type"
          className="select-search-bar w-full md:mx-2 pl-6 py-3 md:outline-none md:w-1/5"
          value={selectedSort}
          onChange={(e) => {
            setSelectedSort(e.target.value);
            onSearch({ selSort: e.target.value });
          }}
        >
          {Object.entries(sortTypes).map(([key, value]) => {
            return (
              <option key={key} value={value}>
                {key}
              </option>
            );
          })}
        </select>
      </>
    </div>
  );
};
