import { useQuery } from "@tanstack/react-query";
import { useCommissionsService } from "modules/admin/services/Commissions";
import { useState } from "react";

export const useFetchCommissionsFiles = (
  queryKey = "commissionsFiles",
  initialParams = {
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
  }
) => {
  const [params, setParams] = useState({
    ...initialParams,
  });
  /* 
    page: "",
    limit: "",
    search: "",
   */
  const { fetchCommissionsFiles } = useCommissionsService();

  const queryResults = useQuery({
    queryKey: [queryKey, params],
    queryFn: () => fetchCommissionsFiles(params),
    enabled: !!params?.signature,
    select: (data) => data.data,
  });

  return { ...queryResults, setParams, params };
};
