import { Logo } from "@common/components";
import { web3AuthInstance } from "App";
import { wagmiConfig } from "App";
import DropdownComponent from "common/components/Dropdown";
import { useGetUserInfo } from "common/hooks/useGetUserInfo";
import { useLoginRedirect } from "common/hooks/useLoginRedirect";
import { formatNumber } from "common/util/formatNumber";
import { replaceWCQrCode } from "common/util/replaceWCQrCode";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, useConnect } from "wagmi";
import UserPlaceholder from "@assets/icons/user.png";
import { useAuctionService } from "modules/penny-auction/context/auctionProvider";

export const Navbar = () => {
  const { user } = useAuctionService();

  const userBids = user ? user.currentBids : undefined;
  const { isConnected, address } = useAccount();
  const { userInfo } = useGetUserInfo();
  useLoginRedirect({ isConnected, address });
  const [profileImg, setProfileImg] = useState("");
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const options = [
    { title: "Dashboard", redirectionUrl: "/app/dashboard" },
    { title: "Store", redirectionUrl: "/app/store" },
    { title: "Profile", redirectionUrl: "/app/profile" },
    { title: "Logout", redirectionUrl: "/logout" },
  ];

  const getUserInfo = useCallback(async () => {
    try {
      const response = await web3AuthInstance.getUserInfo();
      if (response?.profileImage) {
        setProfileImg(response.profileImage);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  useEffect(() => {
    if (web3AuthInstance.connected) getUserInfo();
  }, [web3AuthInstance.connected]);

  const { error, connectors, connect } = useConnect(wagmiConfig);

  const handleJoin = async () => {
    // setShowModal(true);
    if (connect) {
      const obserer = replaceWCQrCode({ web3AuthInstance });
      connect(
        { connector: connectors[0] },
        {
          onSuccess: () => {
            getUserInfo();
          },
          onError: () => {
            obserer.disconnect();
          },
        }
      );
    }
  };
  return (
    <>
      <nav className="border-gray-200 bg-[#F2F2EF] fixed md:static w-full z-40">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <div className="hidden md:block">
            <Logo variant="dark-letters" />
          </div>
          <div className="md:hidden block">
            <Logo variant="dark-letters" width="w-32" />
          </div>
          <div className="flex items-center gap-2 md:hidden">
            {userBids ? (
              <>
                <div className="block md:hidden">
                  <span className="text-[#1840ce]">{formatNumber(userBids)} pts.</span>
                </div>
              </>
            ) : (
              <>
                <div className="block md:hidden">
                  <span className="text-[#1840ce]">0 pts.</span>
                </div>
              </>
            )}
            <div className="md:hidden">
              <div className="flex items-end">
                {userInfo ? (
                  <>
                    <DropdownComponent
                      title={
                        userInfo && (
                          <>
                            {profileImg && profileImg.length > 0 ? (
                              <img
                                className="h-12 w-12 cursor-pointer rounded-full object-cover"
                                src={profileImg}
                              />
                            ) : (
                              <div className="h-12 w-12 bg-clair-gray rounded-full"></div>
                            )}
                          </>
                        )
                      }
                      items={options}
                    />
                  </>
                ) : (
                  <>
                    <button onClick={handleJoin} className="px-2 md:px-10 md:mr-2">
                      Log in
                    </button>
                    <button
                      onClick={handleJoin}
                      className="bg-highlight-secondary hover:bg-zinc-400 text-gray-800 px-2 font-bold md:py-2 md:px-10 md:text-lg"
                    >
                      Join Now
                    </button>
                  </>
                )}
              </div>
            </div>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              dataCollapseToggle="navbar-default"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-default"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                ariaHidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={`${isMenuOpen ? "block" : "hidden"} w-full md:block md:w-auto`}
            id="navbar-default"
          >
            <div className="flex items-start md:items-center gap-2 md:gap-0 my-5 md:my-0 flex-col md:flex-row">
              <div
                className="px-4 cursor-pointer hover:bg-slate-200"
                onClick={() => navigate("/pricing")}
              >
                Shop
              </div>
              <div
                className="px-4 cursor-pointer hover:bg-slate-200"
                onClick={() => navigate("/company")}
              >
                Company
              </div>
              <div
                className="px-4 cursor-pointer hover:bg-slate-200"
                onClick={() => navigate("/benefits")}
              >
                Benefits
              </div>
              <div
                className="px-4 cursor-pointer hover:bg-slate-200"
                onClick={() => navigate("/app/store/winners")}
              >
                See our winners
              </div>
              <div
                className="px-4 cursor-pointer hover:bg-slate-200"
                onClick={() => navigate("/contact-us")}
              >
                Contact us
              </div>
              <hr className="h-6 border-r-2 border-clair-gray mr-4 hidden md:block" />
              {userBids ? (
                <>
                  <div className="pr-4 hidden md:block">
                    {`Current Balance: `}
                    <span className="text-[#1840ce]">{formatNumber(userBids)} pts.</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="pr-4 hidden md:block">
                    {`Current Balance: `}
                    <span className="text-[#1840ce]">0 pts.</span>
                  </div>
                </>
              )}
              {userInfo ? (
                <div className="items-center gap-x-2 hidden md:flex">
                  {profileImg && profileImg.length > 0 ? (
                    <img
                      className="h-12 w-12 cursor-pointer rounded-full object-cover hidden md:block"
                      src={profileImg}
                    />
                  ) : (
                    <img
                      className="h-12 w-12 cursor-pointer rounded-full object-cover hidden md:block"
                      src={UserPlaceholder}
                    />
                  )}
                  <DropdownComponent title={userInfo && `${userInfo.info.name}`} items={options} />
                </div>
              ) : (
                <div className="hidden md:flex items-center gap-x-2">
                  <button onClick={handleJoin} className="px-2 md:px-10 md:mr-2">
                    Log in
                  </button>
                  <button
                    onClick={handleJoin}
                    className="bg-highlight-secondary hover:bg-zinc-400 text-gray-800 px-2 font-bold md:py-2 md:px-10 md:text-lg"
                  >
                    Join Now
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      <div className="h-20 md:hidden bg-transparent" />
    </>
  );
};
